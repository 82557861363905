export default class ShopUpdateForm {
  constructor(data) {
    this.name = data?.name
    this.address = data?.address
    this.isActive = data?.isActive
  }
  static create(data) {
    return new ShopUpdateForm(data)
  }
}
