<template>
  <div>
    <h1 class="text-h4">Управление магазинами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Редактирование магазина {{ id }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-text-field
            v-model="form.data.address"
            label="Адрес"
            counter="250"
            :rules="form.rule.address"
          />
          <v-switch
            v-model="form.data.isActive"
            label="Включено"
            hint="Если включено, то категория становится сразу доступной на сайте"
            persistent-hint
            inset
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'shop.list'}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <meta-view
      :id="metadata.id"
      :loading="busy"
      :created-at="metadata.createdAt"
      :updated-at="metadata.updatedAt"
    ></meta-view>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { ruleRequired } from '@/utils/FormRules'
import { mapActions, mapState } from 'vuex'
import ShopUpdateForm from '@/model/shop/ShopUpdateForm'
import MetaView from '@/components/ui/MetaView.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ShopEditPage',
  components: { MetaView },
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          address: null,
          isActive: false
        },
        rule: {
          name: [ruleRequired()],
          address: [ruleRequired()]
        }
      },
      metadata: {
        id: null,
        createdAt: null,
        updatedAt: null
      }
    }
  },
  computed: {
    ...mapState('shop_edit', ['item', 'busy'])
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.address = this.item.address
        this.form.data.isActive = this.item.isActive

        this.metadata = {
          id: this.id,
          createdAt: this.item.meta.createdAt,
          updatedAt: this.item.meta.updatedAt
        }
      })
  },
  methods: {
    ...mapActions('shop_edit', ['fetchData', 'updateShop']),
    submitForm() {
      const form = ShopUpdateForm.create({ ...this.form.data })

      this.updateShop({ form })
        .then(() => this.$router.push({ name: 'shop.list' }))
    }
  }
}
</script>

<style scoped>

</style>
